.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .popup-content h2 {
    font: normal normal bold 18px/20px Lato;
    letter-spacing: 0px;
    color: #C2BE00; /* Same yellow color */
    opacity: 1;
    margin-bottom: 20px;
  }
  
  .popup-content p {
    font: normal normal 300 12px/14px Lato;
    letter-spacing: 0px;
    color: #243D54; /* Dark blue color */
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .popup-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .popup-close-button {
    background-color: #243D54;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .popup-close-button:hover {
    background-color: #C2BE00; /* Yellow hover effect */
  }